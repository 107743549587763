@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url(https://db.onlinewebfonts.com/c/4b80e612ccda14bcfb1bf48d06e194a1?family=Gontserrat+Bold);
@import url(https://db.onlinewebfonts.com/c/bb80673c91f6bf26198e6b56b4f47ab2?family=Gontserrat+Regular);
@import url(https://db.onlinewebfonts.com/c/2285e99cd2ca526cbe912cf3c4a8b88f?family=Gontserrat+Light);

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 0 0% 5%;

    --card: 0 0% 100%;
    --card-foreground: 0 0% 5%;

    --popover: 0 0% 100%;
    --popover-foreground: 0 0% 5%;

    --primary: 227 97% 64%;
    --primary-foreground: 0 0% 98%;

    --secondary: 0 0% 96%;
    --secondary-foreground: 0 0% 5%;

    --muted: 0 0% 96%;
    --muted-foreground: 0 0% 45%;

    --accent: 0 0% 96%;
    --accent-foreground: 0 0% 5%;

    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 98%;

    --border: 0 0% 92%;
    --input: 0 0% 85%;
    --ring: 0 0% 76%;

    --radius: 0.5rem;
  }

  .dark {
    --background: 240 3% 6%;
    --foreground: 0 0% 98%;

    --card: 210 6% 7%;
    --card-foreground: 0 0% 98%;

    --popover: 210 6% 7%;
    --popover-foreground: 0 0% 98%;

    --primary: 227 97% 64%;
    --primary-foreground: 0 0% 98%;

    --secondary: 225 5% 15%;
    --secondary-foreground: 0 0% 98%;

    --muted: 220 5% 22%;
    --muted-foreground: 230 5% 49%;

    --accent: 227 45% 14%;
    --accent-foreground: 0 0% 98%;

    --destructive: 0 84% 60%;
    --destructive-foreground: 0 0% 98%;

    --border: 220 5% 12%;
    --input: 225 4% 18%;
    --ring: 227 97% 40%;
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply overflow-x-hidden bg-background text-foreground;
  }
}
